import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import clsx from 'clsx';
import { handleLink } from '../../../helpers/general';
import * as styles from './Tab.module.css';

const Tab = ({ active, label, itemKey, uri, onClick }) => {
  const onClickTabHandle = () => {
    if (uri) {
      navigate(handleLink(uri));
    } else if (onClick) {
      onClick(itemKey);
    }
  };

  return (
    <li
      className={clsx(styles.tabListItem, active && styles.tabListItemActive)}
      onClick={onClickTabHandle}
      role="presentation"
    >
      {label}
    </li>
  );
};

Tab.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.node.isRequired,
  itemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired
};

export default Tab;
