import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { head } from 'lodash';
import Tab from '../../atoms/Tab/Tab';
import * as styles from './Tabs.module.css';

const Tabs = ({ items, noWrap, onChangeTab, align = 'center', mode, color, active}) => {
  const firstItem = head(items);
  const [activeTab, setActiveTab] = useState(firstItem !== undefined ? firstItem.itemKey : undefined);

  const tabListClasses = [styles.tabList];

  if (align in styles) {
    tabListClasses.push(styles[align]);
  }

  const tabListClassOutput = tabListClasses.join(' ');

  const onClickTabHandle = tab => {
    setActiveTab(tab);
    if (onChangeTab) {
      onChangeTab(tab);
    }
  };

  // bind
  useEffect(() => {
    if(active !== undefined) {
      setActiveTab(active);
    }
  }, [active])

  // useEffect(() => {
  //   // const firstItem = head(items);
  //   // if (firstItem && !firstItem.uri) {
  //   //   setActiveTab(firstItem.itemKey);
  //   // }
  // }, [items]);

  // default: first item at the list
  // useEffect(() => {
  //   const firstItem = head(items);
  //   setActiveTab(firstItem.itemKey);
  // },[])

  return (
    <div className={clsx(styles.root, noWrap && styles.noWrap)}>
      <ul className={`${tabListClassOutput} ${mode === 'full' ? styles.full: ''}`}>
        {items.map(item => {
          if (item.label === "Uncategorized") {
            return null;
          }
          
          return (
            <Tab
              color={color}
              mode={mode}
              key={item.itemKey}
              label={item.label}
              onClick={onClickTabHandle}
              itemKey={item.itemKey}
              uri={item.uri}
              active={activeTab === item.itemKey || item.active}
            />
          );
        })}
      </ul>
    </div>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      itemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onChangeTab: PropTypes.func
};

export default Tabs;
