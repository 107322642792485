import React from 'react';
import Layout from '../../components/organisms/Layout/Layout';
import PageTitle from '../../components/atoms/PageTitle/PageTitle';
import GiftCertificatesTab from '../../components/organisms/GiftCertificatesTab/GiftCertificatesTab';
import PurchaseForm from '../../components/molecules/PurchaseForm/PurchaseForm';

const GiftcertificatesPage = () => {
  return (
    <Layout>
      <PageTitle
        title="Gift Certificates"
      >
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem illo
          inventore accusantium doloremque laudantium, aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt ipsam.
        </p>
      </PageTitle>

      <GiftCertificatesTab pageId={'purchase-gift-certificate'}>
        <PurchaseForm />
      </GiftCertificatesTab>
    </Layout>
  );
};

export default GiftcertificatesPage;
